import React from 'react';

import AuthenticatedLayout from "../components/layout/AuthenticatedLayout";

import {
    Divider as MuiDivider, Grid,
    styled
} from "@mui/material";
import { spacing } from "@mui/system";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import createTheme from "../styles/theme";
import {THEMES} from "../styles/theme/constants";

import Resources from "../components/view/overviews/dashboard/Resources";
import TagFilter from "../components/filter/TagFilter";

const Divider = styled(MuiDivider)(spacing);

export default function ResourcesPage() {
    return (
        <MuiThemeProvider theme={createTheme(THEMES.DARK)}>
            <AuthenticatedLayout title={'Resources'}
                                 breadcrumbs={[]}
                                 size="xl">
                <TagFilter/>
                <Grid container spacing={2}>
                    <Divider mt={2} mb={4} />
                    <Resources/>
                </Grid>
            </AuthenticatedLayout>
        </MuiThemeProvider>
    )
}
