import React, {useEffect, useState} from "react";
import {Box, CardContent} from "@mui/material";

import {FlatCard} from "../Misc";

import {ChartDataColor, mapColorZr} from "./raw/Charts";
import {useTheme} from "@mui/material/styles";
import {useWindowWidth} from "@react-hook/window-size";
import * as echarts from "echarts/core";

import {EChartsType} from "echarts/core";
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    AriaComponentOption,
    TitleComponentOption,
    GridComponentOption,
    GraphicComponentOption,
    MarkLineComponentOption, DatasetComponentOption
} from 'echarts/components';
import {
    LineSeriesOption,
    PieChart,
    PieSeriesOption
} from 'echarts/charts';
import {
    LabelLayout
} from 'echarts/features';
import {
    CanvasRenderer
} from 'echarts/renderers';

import {dark} from "./raw/theme-dark";
import {light} from "./raw/theme-light";
import {ZRFontWeight} from "echarts/types/src/util/types";

type ECOptions = echarts.ComposeOption<
        | TooltipComponentOption
        | TitleComponentOption
        | LegendComponentOption
        | DatasetComponentOption
        | PieSeriesOption
        >

export interface Props {
    id: string
    data: {value: number, name: string}[]
    header?: string
    subHeader?: string
    showDataLine?: boolean
    color?: ChartDataColor
    unit?: string
    width?: string
    height?: string
}

export default function DoughnutChart(props: Props) {
    const theme = useTheme()
    const windowWidth = useWindowWidth()

    const [chart, setChart] = useState<EChartsType | null>(null)

    const option: ECOptions = {
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                data: props.data
            }
        ]
    }

    const initChart = (): EChartsType => {
        const chart = echarts.init(document.getElementById(props.id)!, theme.palette.mode)
        chart.setOption(option)
        return chart
    }

    echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

    echarts.registerTheme("light", light)
    echarts.registerTheme("dark", dark)

    useEffect(() => {
        setChart(initChart())
    }, [])

    useEffect(() => {
        chart?.resize({silent: true})
    }, [windowWidth])

    return (
        <FlatCard variant="outlined" sx={{width: "100%", height: "100%"}}>
            <CardContent>
                <Box id={props.id} component="div" sx={{height: '300px', width: '100%'}}/>
            </CardContent>
        </FlatCard>
    )
}