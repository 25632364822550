import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import * as echarts from "echarts/core";
import {EChartsType} from "echarts/core";
import {BarChart, BarSeriesOption,} from "echarts/charts";
import {
    AriaComponent,
    AriaComponentOption,
    DatasetComponent,
    DatasetComponentOption,
    GraphicComponent,
    GraphicComponentOption,
    GridComponent,
    GridComponentOption,
    LegendComponent,
    LegendComponentOption,
    MarkLineComponent,
    MarkLineComponentOption,
    TitleComponent,
    TitleComponentOption,
    TooltipComponent,
    TooltipComponentOption,
    TransformComponent,
} from "echarts/components";
import {LabelLayout, UniversalTransition} from "echarts/features";
import {CanvasRenderer} from "echarts/renderers";
import {useTheme} from "@mui/material/styles";
import {light} from "./theme-light";
import {dark} from "./theme-dark";
import {useWindowWidth} from "@react-hook/window-size";

type ECOption = echarts.ComposeOption<BarSeriesOption
        | AriaComponentOption
        | TooltipComponentOption
        | TitleComponentOption
        | LegendComponentOption
        | GridComponentOption
        | GraphicComponentOption
        | MarkLineComponentOption
        | DatasetComponentOption>

export interface Props {
    id: string
    series: BarSeriesOption[]
    title?: TitleComponentOption
    legend?: LegendComponentOption
    grid?: GridComponentOption
    tooltip?: TooltipComponentOption
    graphic?: GraphicComponentOption
    markLine?: MarkLineComponentOption
    width?: string
    height?: string
    xAxisName?: string
}

export default function (props: Props) {
    const theme = useTheme()
    const windowWidth = useWindowWidth()

    const [chart, setChart] = useState<EChartsType | null>(null)

    const option: ECOption = {
        textStyle: {fontFamily: theme.typography.fontFamily},
        animation: false,
        title: props.title,
        aria: {enabled: false, decal: {show: true}},
        legend: {
            show: false,
            icon: "rect",
            ...props.legend,
        },
        tooltip: {
            show: false,
            ...props.tooltip,
        },
        graphic: props.graphic,
        grid: props.grid,
        xAxis: {
            type: "value",
            name: props.xAxisName,
            axisLabel: {
                show: !!props.xAxisName,
                formatter: "{value}"
            }
        },
        yAxis: {
            type: "category",
            axisLabel: {show: false},
        },
        series: props.series.map(s => {
            return {
                type: "bar",
                ...s
            }
        }),
    }
    const initChart = (): EChartsType => {
        const chart = echarts.init(document.getElementById(props.id)!, theme.palette.mode)
        chart.setOption(option)
        return chart
    }

    echarts.use([
        TooltipComponent,
        TitleComponent,
        LegendComponent,
        GridComponent,
        DatasetComponent,
        TransformComponent,
        AriaComponent,
        GraphicComponent,
        MarkLineComponent,
        BarChart,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
    ])
    echarts.registerTheme("light", light)
    echarts.registerTheme("dark", dark)

    useEffect(() => {
        setChart(initChart())
    }, [])
    useEffect(() => {
        chart?.dispose()
        setChart(initChart())
    }, [theme.palette.mode, props.series, props.legend])
    useEffect(() => {
        chart?.resize({silent: true})
    }, [windowWidth])

    return (<Box id={props.id} component="div" sx={{height: props.height, width: props.width}}/>)
}