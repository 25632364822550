import {Grid, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import React from "react";

import DoughnutChart from "../../../chart/DoughnutChart";
import ResourcesAllocatedBarChart from "../../../chart/ResourcesAllocatedBarChart";

export default function Resources() {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Resources
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ResourcesAllocatedBarChart id="all-tenants-cpu" header="CPU"
                                    data={[
                                      {
                                        name: "Allocated",
                                        value: 60,
                                        unit: " cores",
                                        color: "primary"
                                      },
                                      {
                                        name: "Limited",
                                        value: 100,
                                        unit: " cores",
                                        color: "primary"
                                      },
                                    ]}
                                    unit={'CPU'}
                                    dataTotal={{name: "Total", value: 128, unit: " cores"}}
                                    height={isSm ? "200px" : "250px"}/>
      </Grid>
      <Grid item xs={6}>
        <ResourcesAllocatedBarChart id="all-tenants-memory" header="Memory"
                                    data={[
                                      {
                                        name: "Allocated",
                                        value: 500,
                                        unit: " GB",
                                        color: "alert"
                                      },
                                      {
                                        name: "Limited",
                                        value: 600,
                                        unit: " GB",
                                        color: "error"
                                      },
                                    ]}
                                    unit={'CPU'}
                                    dataTotal={{name: "Total", value: 512, unit: " GB"}}
                                    height={isSm ? "200px" : "250px"}/>
      </Grid>

      <Grid item xs={12}>
        <Grid container xs={12} spacing={1} flexWrap={'wrap'}>
          <Grid item xs={6}>
            <DoughnutChart id={"doughnut-chart-nodes"} data={[
                { value: 25, name: 'Used Nodes' },
                { value: 75, name: 'Available Nodes' }
            ]}/>
          </Grid>
          <Grid item xs={6}>
              <DoughnutChart id={"doughnut-chart-vcpu"} data={[
                  { value: 25, name: 'Used vCPU' },
                  { value: 75, name: 'Available vCPU' }
              ]}/>
          </Grid>
          <Grid item xs={6}>
              <DoughnutChart id={"doughnut-chart-gpu"} data={[
                  { value: 25, name: 'Used GPU' },
                  { value: 75, name: 'Available GPU' }
              ]}/>
          </Grid>
          <Grid item xs={6}>
              <DoughnutChart id={"doughnut-chart-memory"} data={[
                  { value: 25, name: 'Used Memory' },
                  { value: 75, name: 'Available Memory' }
              ]}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        Resource Prediction
      </Grid>
    </>
  )
}
